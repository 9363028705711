import { translations as commonBundle } from './common'

let _lang = 'en'

export function setLang(lang) {
  _lang = lang;
}

export function getText(bundle, key) {
  const lang = _lang;
  const defaultLang = 'en'
  let translation;
  translation = commonBundle[key]
  if (translation && translation[lang]) {
    return translation[lang]
  } else if (translation && translation[defaultLang]) {
    return translation[defaultLang]
  } else {
    return 'Not found: ' + bundle + ' ' + key
  }
}
