export const translations = {
  slogan: {
    es: 'LA CLAVE DE TU ÉXITO COMO HOME STAGER',
    en: 'THE KEY OF YOUR SUCCESS AS HOME STAGER',
  },
  mainKeyword: {
    es: 'Gestiona tu inventario de home staging',
    en: 'Manage your home staging inventory'
  },
  plans: {
    es: 'Planes',
    en: 'Pricing',
  },
  tutorials: {
    es: 'Tutoriales',
    en: 'Tutorials',
  },
  motivation: {
    es: 'Para qué?',
    en: 'Purpose',
  },
  suggestions: {
    es: 'Sugerencias',
    en: 'Suggestions',
  },
  planInfo1: {
    es: 'Añadir hasta 100 artículos',
    en: 'Add up to 100 itmes',
  },
  planInfo2: {
    es: 'Añadir artículos',
    en: 'Add items',
  },
  planInfo3: {
    es: 'Crear un proyecto',
    en: 'Create a single project',
  },
  planInfo4: {
    es: 'Asociación de artículos a un proyecto',
    en: 'Associate items to a project',
  },
  planInfo5: {
    es: 'Crear proyectos ilimitados',
    en: 'Create unlimited projects',
  },
  planInfo6: {
    es: 'Generación de informe (PDF)',
    en: 'Generate reports (PDF)',
  },
  planInfo7: {
    es: 'Búsqueda de artículos',
    en: 'Search for items',
  },
  planInfo8: {
      es: 'Asociación de artículos a proyectos',
      en: 'Associate items to projects'
  },
  freeTrial: {
      es: 'Púebalo gratis los 30 primeros días',
      en: 'Free trial 30 days'
  },
  purposeInfo0: {
    es: 'Hemos creado una herramienta que nos ayuda a gestionar los inventarios que necesitamos para la realización de proyectos de home staging, solucionando los problemas que hemos detectado para nuestro caso y esperando ayudar a otros profesionales que se encuentran con situaciones similares a las nuestras en su día a día.',
    en: 'We\'ve built a tool that helps us to manage inventory that we use in our home staging projects. We solved the problems that we found in our cases hopping to help other professionals that fight with similar situations at their projects'
  },
  purposeInfo1: {
      es: 'Organizar tu almacén será muy fácil a partir de ahora , con la aplicación hemos diseñado podrás:',
      en: 'Organize your warehouse will be pretty easy from today, with our app you will be able to do the next:'
  },
  purposeInfo2: {
      es: 'Ver desde la aplicación todo tu inventario disponible con toda la información de cada uno de los objetos.',
      en: 'From the application you can view all your available items with the complete information of each one.'
  },
  purposeInfo3: {
      es: 'Ver que objetos tienes disponibles en tu almacen para siguientes proyectos.',
      en: 'Know all the items you have available in you warehouse to plan your next project.'
  },
  purposeInfo4: {
      es: 'Ver qué objetos tienes en cada una de las intervenciones que tienes instaladas y poder así diseñar tus escenarios cómodamente desde tu despacho.',
      en: 'Know the items you have in each intervention and design your stage from your desk'
  },
  purposeInfo5: {
      es: 'Con un sólo click podrás extraer un pdf con el inventario de cada intervención para que te lo firme tu cliente.',
      en: 'With just a click you can create a report with all items you installed in a project.'
  },
  tutorial1: {
      es: 'Video 1: Como añadir artículos a tu inventario',
      en: 'Video 1: How add articles to the inventory'
  },
  tutorial2: {
      es: 'Video 2: Borrar, editar y buscar artículos de tu inventario',
      en: 'Video 2: Delete, edit and search items in your inventory'
  },
  tutorial3: {
      es: 'Video 3: Crear proyectos y asociar artículos a cada uno de ellos',
      en: 'Video 3: Create projects and associate items'
  },
  tutorial4: {
      es: 'Video 4: Imprimir un pdf con la relación de artículos de un proyecto',
      en: 'Video 4: Get a report in pdf with all items of a project'
  },
  tutorial1Url: {
    es: 'https://youtube.com/embed/ekuMZF1E_S8',
    en: 'https://youtube.com/embed/Xw7e9JUPyu4'
  },
  tutorial2Url: {
      es: 'https://youtube.com/embed/5ulp6h1RVio',
      en: 'https://youtube.com/embed/E2dPDZpRqco'
  },
  tutorial3Url: {
      es: 'https://youtube.com/embed/rFClMLyNbtU',
      en: 'https://youtube.com/embed/7ddYF0MrqAA'
  },
  tutorial4Url: {
      es: 'https://youtube.com/embed/rFClMLyNbtU',
      en: 'https://youtube.com/embed/tx60UmDphMA'
  },
  suggestionInfo: {
      es: 'Envíanos tus sugerencias, qué problemas encuentras en tu día a día y qué te gustaría añadir a la APP.',
      en: 'Send us your feedback, problems on your daily or features you miss in our app.'
  },
  name: {
      es: 'Nombre',
      en: 'Name'
  },
  message: {
      es: 'Mensaje',
      en: 'Message'
  },
  suggestionThanks: {
      es: 'Muchas gracias por tu interés. Te mantendremos informado de los avances.',
      en: 'Thanks for your feedback.'
  },
  suggestionError: {
      es: 'Se ha producido un error en el envío del feedbak, prueba de nuevo más tarde.',
      en: 'An error happened, please try it once again later.'
  },
  privacy1: {
      es: 'He leído y entendido la',
      en: 'I read and understood the'
  },
  privacy2: {
      es: 'política de privacidad',
      en: 'privacy policy'
  },
  sendMsg: {
      es: 'Enviar mensaje',
      en: 'Send message'
  },
  cookieConsent: {
      es: 'Utilizamos cookies para mejorar la experiencia de usuario.',
      en: 'We use cookies to improve user experience'
  },
  accept: {
      es: 'Acepto',
      en: 'Accept'
  },
  startYourTrial: {
      es: 'Empieza tu prueba gratuita durante 30 días descargando nuestra app',
      en: 'Start your 30 days free trial downloading our app'
  }
}
