import PropTypes from "prop-types";
import React from 'react'
import { isUS, isCA } from '../plugins/countryHelper';
import { Message } from '../components/Message';

const Plan = props => {
  return (
    <div className="pricing-wrapper">
      <div className="pricing-table">
        <p className="ccr-price-title">{props.planName}</p>
        <div className="ccr-price">
          <p className="ccr-price-value">
            {(isUS() || isCA()) && '$'}{props.planPrice}{!isUS() && !isCA() && '€'}<br />
            {(isUS() || isCA()) && 'Mo'}
            {!isUS() && !isCA() && 'Mes'}
          </p>
        </div>
        {props.isFree && (
          <ul className="ccr-price-list">
            <li><Message bundle="common" messageKey="planInfo1"/></li>
            <li><Message bundle="common" messageKey="planInfo7"/></li>
          </ul>
        )}
        {!props.isAdvanced && !props.isFree && (
          <ul className="ccr-price-list">
            <li style={{color: 'red'}}><Message bundle="common" messageKey="freeTrial"/></li>
            <li><Message bundle="common" messageKey="planInfo2"/></li>
            <li><Message bundle="common" messageKey="planInfo7"/></li>
            <li><Message bundle="common" messageKey="planInfo3"/></li>
            <li><Message bundle="common" messageKey="planInfo4"/></li>
          </ul>
        )}
        {props.isAdvanced && (
          <ul className="ccr-price-list">
            <li><Message bundle="common" messageKey="planInfo2"/></li>
            <li><Message bundle="common" messageKey="planInfo7"/></li>
            <li><Message bundle="common" messageKey="planInfo5"/></li>
            <li><Message bundle="common" messageKey="planInfo8"/></li>
            <li><Message bundle="common" messageKey="planInfo6"/></li>
          </ul>
        )}
      </div>
      <style jsx global>{`
        .pricing-wrapper {
            width: 32%;
            float: left;
            margin-right: 1%;
        }
        .pricing-table {
          border: 1px solid #eee;
          display: block;
          text-align: center;
          -webkit-transition: all 0.4s ease 0s;
          transition: all 0.4s ease 0s;
          background-color: #fff;
        }
        .ccr-price-title {
          background: #2a80b9 none repeat scroll 0 0;
          color: #fff;
          font-family: montserrat, sans-serif;
          font-size: 20px;
          height: 120px;
          padding: 20px 0 10px;
          text-align: center;
          text-transform: uppercase;
        }
        .ccr-price {
          margin-bottom: 30px;
        }
        .ccr-price-value {
          border: 4px solid #f7f7f7;
          border-radius: 100%;
          box-shadow: 0 5px 13px -4px #585858 inset;
          font-family: montserrat, sans-serif;
          color: #fff;
          font-size: 20px;
          height: 120px;
          line-height: 30px;
          margin-left: auto;
          margin-right: auto;
          margin-top: -60px;
          padding-top: 30px;
          text-align: center;
          text-shadow: 1px 1px 0 #fff;
          transition: all 0.4s ease 0s;
          width: 120px;
          background: #2a80b9; /* Old browsers */ /* FF3.6+ */
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, #2a80b9),
            color-stop(40%, #2a80b9)
          ); /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(
            top,
            #2a80b9 0%,
            #2a80b9 40%
          ); /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
          background: -webkit-linear-gradient(top, #2a80b9 0%, #2a80b9 40%);
          background: linear-gradient(
            to bottom,
            #2a80b9 0%,
            #2a80b9 40%
          ); /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a80b9', endColorstr='#2a80b9',GradientType=0 ); /* IE6-9 */
        }
        .ccr-price-list li {
          border-top: 1px dashed #eaeaea;
          color: #333;
          font-family: lora, sans-serif;
          font-size: 16px;
          line-height: 50px;
          list-style: outside none none;
          position: relative;
          text-align: center;
          transition: all 0.3s ease 0s;
        }
        .ccr-price-list li:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
        .pricing-table button {
          font-family: montserrat, sans-serif;
          margin: 20px 0;
          padding: 9px 30px;
        }
        .btn-price-bg {
          background: #2a80b9 none repeat scroll 0 0;
          border: medium none;
          border-radius: 30px;
          box-shadow: 0 11px 10px 0 rgba(0, 0, 0, 0.1);
          color: #fff;
          font-size: 14px;
          padding: 10px 30px;
          text-transform: uppercase;
          transition: all 0.2s ease 0s;
        }
        .btn-price-bg:hover {
          background: #333;
          color: #fff;
        }
      `}</style>
    </div>
  );
};

Plan.propTypes = {
  subscribe: PropTypes.func.isRequired,
  planName: PropTypes.string.isRequired,
  planPrice: PropTypes.string.isRequired,
  isAdvanced: PropTypes.bool,
  isUpdate: PropTypes.bool,
  isFree: PropTypes.bool
};

export default Plan;
