import Cookies from "js-cookie";

export function isUS() {
    return Cookies.get('countryCode') === 'US';
}

export function isCA() {
    return Cookies.get('countryCode') === 'CA';
}

export function getCountry() {
    return Cookies.get('countryCode') || 'US';
}