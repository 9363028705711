import React from 'react'
import PropTypes from 'prop-types'

import lamp from '../images/lamp.png'
import lampOn from '../images/lampOn.png'
import { Message } from './Message';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.changeLogo = this.changeLogo.bind(this);
    }

    state = {
        isLampOn: false
    }

    changeLogo() {
        this.props.onLight();
        this.setState({isLampOn: !this.state.isLampOn});
    }

    render() {
        let baseUrl = '/'
        if(this.props.location.pathname === '/') {
            baseUrl = '/en'
        } else if (this.props.location.pathname.indexOf('/en/') !== -1) {
            baseUrl = '/en'
        } else {
            baseUrl = '/es';
        }
        return (
            <header id="header" style={this.props.timeout ? {display: 'none'} : {}}>
            <div className="logo" onClick={this.changeLogo}>
                <img src={this.state.isLampOn ? lampOn : lamp} className="image" alt="lamp"/>
            </div>
            <div className="content">
                <div className="inner">
                    <h1>OrganizeStaging</h1>
                    <h2><Message bundle="common" messageKey="mainKeyword"/></h2>
                    <p><Message bundle="common" messageKey="slogan"/></p>
                </div>
            </div>
            <nav>
                <ul>
                    <li><a href="/app/login">Login</a></li>
                    <li><a href={`${baseUrl}/pricing`} onClick={(e) => {this.props.onOpenArticle('plan', e)}}><Message bundle="common" messageKey="plans"/></a></li>
                    <li><a href={`${baseUrl}/tutorials`} onClick={(e) => {this.props.onOpenArticle('tutorials', e)}}><Message bundle="common" messageKey="tutorials"/></a></li>
                    <li><a href={`${baseUrl}/purpose`} onClick={(e) => {this.props.onOpenArticle('intro', e)}}><Message bundle="common" messageKey="motivation"/></a></li>
                    <li><a href={`${baseUrl}/suggestions`} onClick={(e) => {this.props.onOpenArticle('contact', e)}}><Message bundle="common" messageKey="suggestions"/></a></li>
                </ul>
            </nav>
        </header>
        )
    }
}

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
    onLight: PropTypes.func,
    location: PropTypes.object
}

export default Header
