import React from 'react'
import PropTypes from 'prop-types'
import { getText } from '../resources/index'

export const Message = props => {
  return <span>{getText(props.bundle, props.messageKey)}</span>
}

Message.propTypes = {
  bundle: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
}
