import React from 'react'
import PropTypes from 'prop-types'

import pic11 from '../images/pic11.jpg'
import axios from "axios"
import Plan from './Plan';
import { isUS, isCA } from '../plugins/countryHelper';
import { Message } from './Message';
import { getText } from '../resources';
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubscriptionSubmit = this.handleSubscriptionSubmit.bind(this);
  }

  state = {
    subscribeEmail: '',
    contactEmail: '',
    contactName: '',
    contactMsg: '',
    subscribed: false,
    subscriptionError: false,
    contacted: false,
    contactError: false,
    subscribeCheck: false,
    contactCheck: false,
    shouldCheckPrivacy: false
  }

  componentWillReceiveProps(newProps){
    if (newProps.article !== this.props.article) {
      this.setState( {
        subscribeEmail: '',
        contactEmail: '',
        contactName: '',
        contactMsg: '',
        subscribed: false,
        subscriptionError: false,
        contacted: false,
        contactError: false,
        subscribeCheck: false,
        contactCheck: false,
        shouldCheckPrivacy: false
      });
    }
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if(name === 'contactCheck' || name === 'subscribeCheck') {
      this.setState({
        [name]: target.checked,
      });
      if(target.checked) {
        this.setState({shouldCheckPrivacy: false});
      }
    } else {
      this.setState({
        [name]: value,
      })
    }
  }

  handleSubscriptionSubmit = event => {
    event.preventDefault();
    if(!this.state.subscribeCheck) {
      return this.setState({shouldCheckPrivacy: true});
    }
    const that = this;
    axios.post('/api/subscribe', {email: this.state.subscribeEmail})
      .then(function (response) {
        if (response.status < 300) {
          that.setState({subscribed: true});
          that.setState({subscriptionError: false});
        } else {
          that.setState({subscriptionError: true});
          that.setState({subscribed: false});
        }
     })
      .catch(function() {
       that.setState({subscriptionError: true});
       that.setState({subscribed: false});
    });
  }

  handleContactSubmit = event => {
    event.preventDefault();
    if(!this.state.contactCheck) {
      return this.setState({shouldCheckPrivacy: true});
    }
    const that = this;
    const state = this.state;
    axios.post('/api/contact', {
      email: state.contactEmail,
      name: state.contactName,
      msg: state.contactMsg
    })
      .then(function (response) {
        if (response.status < 300) {
          that.setState({contacted: true});
          that.setState({contactError: false});
        } else {
          that.setState({contactError: true});
          that.setState({contacted: false});
        }
     })
      .catch(function (error) {
       that.setState({contactError: true});
       that.setState({contacted: false});
    });
  }

  render() {
    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>
    const back = <input type="submit" value="Volver" className="special" onClick={() => {this.props.onCloseArticle()}}/>
    const privacyClass = this.state.shouldCheckPrivacy ? 'privacyError checkMsg' : 'checkMsg'
    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major"><Message bundle="common" messageKey="motivation"/></h2>
          <h3 className="main-purpose"><Message bundle="common" messageKey="purposeInfo0"/></h3>
          <p><Message bundle="common" messageKey="purposeInfo1"/></p>
          <ul>
            <li><Message bundle="common" messageKey="purposeInfo2"/></li>
            <li><Message bundle="common" messageKey="purposeInfo3"/></li>
            <li><Message bundle="common" messageKey="purposeInfo4"/></li>
            <li><Message bundle="common" messageKey="purposeInfo5"/></li>
          </ul>
          <span className="image main"><img className="appdemo" src={pic11} alt="" /></span>
          
          {close}
        </article>

        <article id="plans" className={`${this.props.article === 'plan' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major"><Message bundle="common" messageKey="plans"/></h2>
          {(isUS() || isCA()) && <Plan planName="Free" planPrice="0" isFree/>}
          {(isUS() || isCA()) && <Plan planName="Basic" planPrice="24.99"/>}
          {(isUS() || isCA()) && <Plan planName="Advanced" planPrice="34.99" isAdvanced/>}
          {!isUS() && !isCA() && <Plan planName="Gratis" isFree planPrice="0"/>}
          {!isUS() && !isCA() && <Plan planName="Básico" planPrice="15,49"/>}
          {!isUS() && !isCA() && <Plan planName="Avanzado" planPrice="24,99" isAdvanced/>}
          
          {close}
        </article>

        <article id="tutorials" className={`${this.props.article === 'tutorials' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major"><Message bundle="common" messageKey="tutorials"/></h2>
          <ul>
            <li>
              <div className="row mt-5">
                <div className="col-12"><Message bundle="common" messageKey="tutorial1"/></div><br></br>
                <div class="video-responsive">
                  <iframe src={getText("common", 'tutorial1Url')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </li>
            <li>
              <div className="row mt-5">
                <div className="col-12"><Message bundle="common" messageKey="tutorial2"/></div><br></br>
                <div className="video-responsive">
                  <iframe src={getText("common", 'tutorial2Url')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </li>
            <li>
              <div className="row mt-5">
                <div className="col-12"><Message bundle="common" messageKey="tutorial3"/></div><br></br>
                <div class="video-responsive">
                  <iframe src={getText("common", 'tutorial3Url')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </li>
            <li>
              <div className="row mt-5">
                <div className="col-12"><Message bundle="common" messageKey="tutorial4"/></div><br></br>
                <div class="video-responsive">
                  <iframe src={getText("common", 'tutorial4Url')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </li>
          </ul>
          
          {close}
        </article>
        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Suscríbete</h2>
          {!this.state.subscribed && <div>
            <p>Déjanos tu email y nos pondremos en contacto contigo cuando tengamos novedades sobre la nueva APP.</p>
            <form method="post" action="#" onSubmit={this.handleSubscriptionSubmit}>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input 
                  type="text"
                  name="subscribeEmail"
                  id="email"
                  value={this.state && this.state.subscribeEmail}
                  onChange={this.handleInputChange}
                  />
              </div>
              <input 
                type="checkbox"
                name="subscribeCheck"
                id="subscribeCheck"
                checked={!!this.state.subscribeCheck}
                onChange={this.handleInputChange}></input>
              <label htmlFor="subscribeCheck" className={privacyClass}>He leído y entendido la <a href="https://rosananunez.com/docs/RN-privacy.pdf" target="_blank" rel="noopener noreferrer">política de privacidad</a></label>
              <ul className="actions">
                <li>
                  <input 
                    type="submit"
                    value="Sí, quiero"
                    className="special"/>
                </li>
              </ul>
            </form>
          </div>}
          {this.state.subscribed &&
            <div>
              <p>Muchas gracias por tu interés. Te mantendremos informado de los avances.</p>
              {back}
            </div>}
          {this.state.subscriptionError && <p>Se ha producido un error en la suscripción, prueba de nuevo más tarde.</p>}
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major"><Message bundle="common" messageKey="suggestions"/></h2>
          {!this.state.contacted && <section>
            <p><Message bundle="common" messageKey="suggestionInfo"/></p>
            <form method="post" action="#" onSubmit={this.handleContactSubmit}>
              <div className="field half first">
                <label htmlFor="name"><Message bundle="common" messageKey="name"/></label>
                <input type="text"
                  name="contactName"
                  id="name"
                  value={this.state.contactName}
                  onChange={this.handleInputChange}/>
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="text"
                  name="contactEmail"
                  id="email"
                  onChange={this.handleInputChange}
                  value={this.state.contactEmail}
                />
              </div>
              <div className="field">
                <label htmlFor="message"><Message bundle="common" messageKey="message"/></label>
                <textarea 
                  name="contactMsg"
                  id="message"
                  rows="4"
                  onChange={this.handleInputChange}
                  value={this.state.contactMsg}
                ></textarea>
              </div>
              <input 
                type="checkbox"
                name="contactCheck"
                id="contactCheck"
                checked={!!this.state.contactCheck}
                onChange={this.handleInputChange}></input>
              <label htmlFor="contactCheck" className={privacyClass}><Message bundle="common" messageKey="privacy1"/> <a href="https://rosananunez.com/docs/RN-privacy.pdf" target="_blank" rel="noopener noreferrer"><Message bundle="common" messageKey="privacy2"/></a></label>
              <ul className="actions">
                <li>
                  <input 
                    type="submit"
                    value={getText('common', 'sendMsg')}
                    className="special" />
                  </li>
              </ul>
            </form>
          </section>}
          {this.state.contacted && 
            <div>
              <p><Message bundle="common" messageKey="suggestionThanks"/></p>
              {back}
            </div>}
          {this.state.contactError && <p><Message bundle="common" messageKey="suggestionError"/></p>}
          {false && <ul className="icons">
            <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>}
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main