import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import CookieConsent from "react-cookie-consent";

import '../assets/scss/main.scss'
import icon from '../../src/images/gatsby-icon.png'
import favicon from '../images/favicon.ico'

import { Message } from './Message';
import { getText, setLang } from '../resources';

const Layout = ({ children, location }) => {

  let content;
  const ldJSON = [{
    "@id": "tutorial1",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Como añadir artículos a tu inventario",
    "description": "Video donde se explica como añadir artículos a tu inventario en la aplicación organizestaging para organizar tu inventario y ayudar en tus proyectos de homestaging.",
    "embedUrl": "https://youtube.com/embed/ekuMZF1E_S8",
    "inLanguage": "es",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial1",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "How add articles to the inventory",
    "description": "In this video we show how you can add articles to your homestaging inventory",
    "embedUrl": "https://youtube.com/embed/Xw7e9JUPyu4",
    "inLanguage": "en",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial2",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Borrar, editar y buscar artículos de tu inventario",
    "description": "Video donde se explica como editar y buscar artículos a tu inventario a través de la aplicación Organizestaging para organizar tu inventario y ayudarte en tu día a día como homestager.",
    "embedUrl": "https://youtube.com/embed/5ulp6h1RVio",
    "inLanguage": "es",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial2",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Delete, edit and search items in your inventory",
    "description": "Video to show how can you edit or search articles of your homestaging inventory through Organizestaging APP.",
    "embedUrl": "https://youtube.com/embed/E2dPDZpRqco",
    "inLanguage": "en",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial3",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Crear proyectos y asociar artículos a cada uno de ellos",
    "description": "Video donde se explica como crear proyectos y asociar artículos a cada uno de ellos utilizando la aplicación Organizestaging, que nos ayuda a gestionar el inventario en proyectos homestaging.",
    "embedUrl": "https://youtube.com/embed/rFClMLyNbtU",
    "inLanguage": "es",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial3",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Create projects and associate items",
    "description": "Video where we explain how to create projects and associate items through Organizestaging APP.",
    "embedUrl": "https://youtube.com/embed/7ddYF0MrqAA",
    "inLanguage": "en",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial4",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Imprimir un pdf con la relación de artículos de un proyecto",
    "description": "Video donde se explica podemos generar los informes con todos los artículos que tenemos instalados en un proyecto de homestaging.",
    "embedUrl": "https://youtube.com/embed/y8Lg-lxfcig",
    "inLanguage": "es",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  },
  {
    "@id": "tutorial4",
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Get a report in pdf with all items of a project",
    "description": "In this video we'll see how to create a report with all items installed in a project throigh Organizestaging APP.",
    "inLanguage": "en",
    "embedUrl": "https://youtube.com/embed/tx60UmDphMA",
    "thumbnailUrl": "https://organizestaging.com/static/tutorial.jpg",
    "uploadDate": "2019-12-29T18:00:00+02:00"
  }];
  let description = 'Organizestaging is an APP to help homestagers to organize their inventory and easier homestaging projects.'
  let keywords = 'homestaging inventory homestagers organize organise home staging';
  let title = 'Organizestaging - Help you to manage your homestaging inventory';
  let lang = 'en';
  if (location && location.pathname === '/' || location && location.pathname === '/es' || location && location.pathname === '/en') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }
  if (location && /es/.test(location.pathname)) {
    lang = 'es';
    setLang(lang);
    title = 'Organizestaging - La app para gestión de inverntario en proyectos de homestagind';
    description = 'Organizestaging es un APP para gestionar el inventario en proyectos homestaging.';
    keywords = 'homestaging inventario homestagers organizar home staging'
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={title}
            meta={[
              { name: 'description', content: description },
              { property: 'og:site_name', content: "organizestaging.com" },
              { property: 'og:type', content: "website" },
              { property: 'og:url', content: "http://organizestaging.com" },
              { property: 'og:description', content: description},
              { property: 'og:image', content: icon },
              { name: 'keywords', content: keywords },
            ]}
          >
          <html lang={lang} />
          <link rel="icon" type="image/png" href={favicon}/>
          <link rel="alternate" hreflang="es" href="https://www.organizestaging.com/es/"></link>
          <link rel="alternate" hreflang="en" href="https://www.organizestaging.com/"></link>
          <link rel="preconnect" href="https://googleads.g.doubleclick.net"></link>
          <link rel="preconnect" href="https://www.google-analytics.com"></link>
          <link rel="preconnect" href="https://static.doubleclick.net"></link>
          <link rel="preconnect" href="https://www.google.com"></link>
          <link rel="preconnect" href="https://marketingplatform.google.com"></link>
          <script type="application/ld+json">
            {JSON.stringify(ldJSON)}
          </script>
          </Helmet>
          {content}
          <CookieConsent
            buttonText={getText('common', 'accept')}
            expires={365}>
              <Message bundle="common" messageKey="cookieConsent"/>
          </CookieConsent>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
